/* AdminDashboard.css */
.dashboard {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .dashboard button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    float: right;
    transition: background-color 0.3s ease;
  }
  
  .dashboard button:hover {
    background-color: #ff0000;
  }
  
  .dashboard h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .product-card img {
    max-width: 100%;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .product-card h3 {
    margin: 10px 0;
    font-size: 20px;
  }
  
  .product-card p {
    font-size: 16px;
    color: #555;
  }
  
  .product-card button {
    margin: 5px 0;
    padding: 8px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .product-card button:nth-child(2) {
    background-color: #28a745;
    color: white;
  }
  
  .product-card button:nth-child(1) {
    background-color: #dc3545;
    color: white;
  }
  
  .product-card button:hover:nth-child(2) {
    background-color: #218838;
  }
  
  .product-card button:hover:nth-child(1) {
    background-color: #c82333;
  }
  