.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 4rem 2rem;
    background: linear-gradient(135deg, #f4f4f9, #e0e7ff);
    color: #003366;
    text-align: center;
    box-shadow: inset 0 -8px 12px rgba(0, 51, 102, 0.2);
}

.contact-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2.5rem;
    color: #003366;
    animation: fadeInDown 0.8s ease-out;
}

.contact-content {
    width: 90%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: space-between;
    align-items: flex-start;
}

.contact-info {
    width: 30%;
    text-align: left;
    font-size: 1.1rem;
}

.contact-info h3 {
    font-size: 1.3rem;
    margin-top: 1rem;
    color: #003366;
}

.contact-info p {
    margin: 0.5rem 0;
    color: #555;
}

.contact-socials {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.contact-socials a {
    font-size: 1.5rem;
    color: #003366;
    transition: color 0.3s ease;
}

.contact-socials a:hover {
    color: #ffd700;
}

.contact-form {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    background: #ffffff;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.contact-form input,
.contact-form textarea {
    padding: 1rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    transition: all 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
    box-shadow: 0 0 10px rgba(0, 51, 102, 0.3);
    border-color: #003366;
}

.contact-submit {
    padding: 1rem;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: #ffd700;
    color: #003366;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.contact-submit:hover {
    background-color: #ffcc00;
    transform: scale(1.05);
}

.contact-map {
    width: 100%;
    max-width: 600px;
    height: 400px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.contact-map iframe {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 12px;
}

/* Keyframes */
@keyframes fadeInDown {
    from { opacity: 0; transform: translateY(-30px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .contact-content {
        flex-direction: column;
        align-items: center;
    }

    .contact-form, .contact-info, .contact-map {
        width: 100%;
    }

    .contact-title {
        font-size: 2rem;
    }
}
