/* Counter.css */

.counter-section {
    text-align: center;
    padding: 2rem 1rem;
    background-color: #f8f8f8;
  }
  
  .counter-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    width: 90%;
    margin: 50px auto;
  }
  
  .counter-item {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .counter-item:hover {
    transform: scale(1.05);
  }
  
  .counter-item h3 {
    font-size: 2.5rem;
    color: #ff6b35;
  }
  
  .counter-item p {
    font-size: 1rem;
    color: #333333;
    margin-top: 0.5rem;
  }
  