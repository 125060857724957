/* Home.css */

.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    gap: 2rem;
  }
  
  .advert-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    width: 90%;
    padding: 1.5rem;
    border-radius: 8px;
    background: #f8f8f8;
  }
  
  .advert-image {
    width: 30%;
    height: 300px;
    flex-shrink: 0;
    transition: transform 0.3s ease;
  }
  
  .advert-image img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  
  .advert-text {
    width: 65%;
    height: 250px;
    margin: 20px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
    background: #ece8e8;
    padding: 20px 0;
  }
  .advert-image:hover, .advert-text:hover {
    transform: scale(1.02);
  }
  
  .advert-text h2 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    text-decoration: underline green;
  }
  
  .advert-text p {
    color: #555;
    padding: 20px;
  }
  
  /* Reverse layout for second advert section */
  
  
  @media (max-width: 768px) {
    .advert-section {
      flex-direction: column;
      text-align: center;
    }
  
    .advert-image, .advert-text {
      width: 100%;
      height: 300px;
    }

    .advert-middle{
        flex-direction: column-reverse;
    }
  }
  