/* AddProductForm.css */
.add-product-form {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .add-product-form h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .add-product-form input[type="text"],
  .add-product-form textarea,
  .add-product-form input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .add-product-form input[type="text"]:focus,
  .add-product-form textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .add-product-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-product-form button:hover {
    background-color: #0056b3;
  }
  