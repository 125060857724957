/* SingleProduct.css */
.single-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    transition: all 0.3s ease; /* Smooth transition for animations */
  }
  
  .product-image {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .product-image:hover {
    transform: scale(1.05); /* Zoom effect on hover */
  }
  
  .product-details {
    text-align: center;
    margin-top: 20px;
  }
  
  .product-title {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .product-description {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 15px;
  }
  
  .product-price {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .add-to-cart {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2em;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
  }
  
  .add-to-cart:hover {
    background-color: #0056b3;
    transform: translateY(-2px); /* Lift effect on hover */
  }
  
  .loading, .error {
    text-align: center;
    font-size: 24px;
    color: #333;
    padding: 50px;
  }
  