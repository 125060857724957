/* ProductList.css */
.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
    margin: 0 auto;
    
    transition: all 0.3s ease; 
  }
  
  .product-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin: 15px;
    padding: 20px;
    width: 300px; 
    text-align: center;
    overflow: hidden;
    transform: translateY(0);
    transition: transform 0.3s ease; 
  }
  
  .product-card:hover {
    transform: translateY(-5px); 
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); 
  }
  
  .product-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .loading {
    text-align: center;
    font-size: 24px;
    color: #333;
    padding: 50px;
  }
  