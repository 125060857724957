.about-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 50px;
  background: linear-gradient(135deg, #b2b3b4, #f4f4f9);
  color: #e6ebef;
}

.about-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #003366;
  animation: fadeInDown 1s ease-out;
}

.about-description {
  font-size: 1.2rem;
  max-width: 600px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 2rem;
  color: #003366;
  animation: fadeIn 1.5s ease-out;
  background-color: #ffd700;
  border-radius: 10px;
  padding: 20px;
}

.about-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

.about-card {
  width: 300px;
  background: linear-gradient(135deg, #ffffff, #e0e5ec);
  color: #003366;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeInUp 2s ease-out;
  transition: transform 0.3s ease;
}

.about-card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.about-ceo{
  margin-top: 50px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #003366, #667f99);
  color: white;
  padding: 20px;
  border-radius: 15px;
}

.about-ceo img{
  width: 150px;
  height: 150px;
  border: 4px solid orange;
  border-radius: 50%;
}

.about-ceo h3{
  margin-bottom: 30px;
}
/* Team Section */
.about-team {
  margin: 50px 0;
  padding: 2rem;
  background: linear-gradient(135deg, #003366, #667f99);
  border-radius: 15px;
  color: #333;
  animation: fadeInUp 2s ease-out;
}

.about-team h3{
  color: #e0e5ec;
}

.team-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
}

.team-member {
  overflow: hidden;
  background: linear-gradient(135deg, #ffffff, #f4f4f9);
  width: 30%;
  height: auto;
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.7s ease, box-shadow 0.3s ease,;
  animation: fadeInUp 2s ease-out;
}



.team-member p {
  font-size: 14px;
}

.team-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 1rem;
  border: 3px solid #003366;
}

/* Keyframes for animations */
@keyframes fadeInDown {
  from { opacity: 0; transform: translateY(-30px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(30px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .about-title{
    width: 90%;
    margin-bottom: 50px;
  }
  .about-description {
    font-size: 1rem;
    text-align: left;
  }

  .about-card {
    font-size: 0.95rem;
    width: 100%;
    max-width: 300px;
  }

  .about-ceo{
    width: 90%;
    padding: 20px 0;
  }

  .about-team{
    width: 90%;
  }

  .team-member{
    height: 100px;
    width: 150px;
  }

  
.team-member:hover{
  height: auto;
  background: linear-gradient(135deg, #003366, #667f99);
  color: white;
  width: auto;
  margin: 0px;
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}
}
