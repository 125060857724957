/* Navbar.css */


  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #003366;
    color: #fff;
    position: relative;
    z-index: 10;
    border-bottom: 0.3px solid #0861ba;
  }
  
  .navbar-logo img{
    width: 150px;
    border-radius: 4px;
  }
  
  .navbar-links {
    display: flex;
    gap: 1.5rem;
    transition: transform 0.5s ease;
  }
  
  .navbar-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
  }
  
  .hamburger-icon {
    display: none;
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
  }

  .logout{
    background: orange;
    border-radius: 5px;
    border: none;
    padding: 5px;
  }
  
  /* Mobile menu (right-to-left slide) */
  @media (max-width: 768px) {
    .navbar-links {
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      width: 70%;
      flex-direction: column;
      background-color: #003366;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      transform: translateX(100%);
      transition: transform 0.5s ease;
    }
  
    .navbar-links.open {
      transform: translateX(0);
    }
  
    .navbar-links a {
      padding: 1rem 0;
      font-size: 1.2rem;
    }
  
    .hamburger-icon {
      display: block;
      z-index: 2222;
      position: fixed;
      right: 20px;
      background: #003366;
      border: 0.3px solid white;
      border-radius: 4px;
      padding: 5px;
    }
  }
  