/* Register.css */
.register-container {
    max-width: 400px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .register-form input {
    padding: 0.75rem;
    border-radius: 6px;
    border: 1px solid #ccc;
    font-size: 1rem;
  }
  
  .register-button {
    padding: 0.75rem;
    border: none;
    background-color: #003366;
    color: white;
    font-size: 1rem;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .register-button:hover {
    background-color: #0055cc;
  }
  
  .error-message {
    color: red;
    font-size: 0.9rem;
  }
  
  .success-message {
    color: green;
    font-size: 0.9rem;
  }
  
  @media (max-width: 768px) {
    .register-container {
      width: 90%;
    }
  }
  