/* HeroSection.css */

.hero-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px 20px;
    height: 100vh;
    position: relative;
    overflow: hidden;
    color: #ffffff;
    background: linear-gradient(45deg, #003366, #1e90ff);
  }
  
  .hero-content {
    position: relative;
    z-index: 2;
    width: 90%;
    display: flex;align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .hero-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 20px;
    animation: slideInFromLeft 1s ease-out;
  }
  
  .hero-title span {
    color: #FFD700; /* Gold for emphasis */
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
    font-weight: 300;
    color: #f0f0f0;
    margin-bottom: 30px;
    animation: fadeIn 2s ease-in;
  }
  
  .hero-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #ffd700;
    border: none;
    border-radius: 30px;
    font-size: 1rem;
    font-weight: bold;
    color: #003366;
    cursor: pointer;
    transition: background-color 0.3s ease;
    animation: slideInFromBottom 1.5s ease-out;
  }
  
  .hero-button:hover {
    background-color: #ffcc00;
  }
  
  .arrow-icon {
    margin-left: 10px;
    transition: transform 0.3s ease;
  }
  
  .hero-button:hover .arrow-icon {
    transform: translateX(5px);
  }
  
  /* Background animation */
  .hero-bg-animation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, rgba(0, 51, 102, 0.5), rgba(0, 0, 0, 0.8));
    animation: rotateGradient 15s linear infinite;
    z-index: 1;
  }
  
  /* Keyframes for animations */
  @keyframes slideInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideInFromBottom {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes rotateGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .hero-title {
      font-size: 2rem;
    }
  
    .hero-subtitle {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .hero-container{
      height: 75vh;
    }
    .hero-title {
      font-size: 1.6rem;
      width: 90%;
    }
  
    .hero-subtitle {
      font-size: 0.9rem;
    }
  
    .hero-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  