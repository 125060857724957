* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

p{
  font-size: 1rem;
}
@media screen and (max-width: 768px) {
  p{
    font-size: 0.8;
  }
}


.side-box, .side-box2, .scroll-to-top{
  position: fixed;
  border: 1px solid #fff;
  background: blue;
  z-index: 2222;
  color: white;
  font-size: 16px;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  right: 5px;
  width: 50px;
  height: 50px;
}

.side-box {
  bottom: 90px;
}

.side-box2{
  bottom: 30px;
}
.side-box:hover, .side-box2:hover{
   background:royalblue;
}

/* Scroll to Top Button */
.scroll-to-top {
  bottom: 150px;
  border-radius: 50%;
  height: 50px;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 0.7;
}

.scroll-to-top:hover {
  opacity: 1;
}

/* styles.css */
.dashboard, .login-container, .add-product-form, .product-card {
  max-width: 100%;
  padding: 20px;
  margin: 0 auto;
  text-align: center;
}

.product-card {
  display: flex;
  flex-direction: column;
  max-width: 201px;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .product-list {
    display: grid;
    grid-template-columns: 1fr;
  }
}

