/* login.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #4facfe, #00f2fe);
    padding: 20px;
  }
  
  .login-form {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 400px;
    width: 100%;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
    text-align: center;
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
  }
  
  .login-form input:focus {
    border-color: #4facfe;
    outline: none;
  }
  
  .login-form button {
    width: 100%;
    padding: 10px;
    background-color: #4facfe;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-form button:hover {
    background-color: #00f2fe;
  }
  
  @media (max-width: 480px) {
    .login-form {
      padding: 20px;
    }
  
    .login-form h2 {
      font-size: 1.5em;
    }
  
    .login-form input {
      font-size: 14px;
    }
  
    .login-form button {
      font-size: 14px;
    }
  }
  