.services-container {
  padding: 4rem 2rem;
  text-align: center;
  background: linear-gradient(135deg, #d8dde1, #e2e3e4);
}

.services-title {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
  animation: fadeInDown 1s ease-out;
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.service-card {
  width: 280px;
  padding: 1.5rem;
  background: linear-gradient(135deg, #ffd700, #ffcc33);
  border-radius: 12px;
  color: #003366;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeInUp 1.5s ease-out;
}

.service-card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.service-card h3 {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.service-card p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}
.contact-service{
  text-decoration: none;
  color: white;
  background: #333;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  border: inset;
  position: fixed;
  right: 20px;
  top: 90px;
  z-index: 333;
}

/* Keyframes */
@keyframes fadeInDown {
  from { opacity: 0; transform: translateY(-30px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(30px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .services-title {
    font-size: 1.8rem;
  }

  .service-card {
    width: 100%;
    max-width: 400px;
  }
}
