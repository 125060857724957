.learnmore-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #f0f8ff;
    color: #003366;
    animation: faadeIn 1s ease;

}

.learnmore-header {
    text-align: center;
    margin-bottom: 3rem;
    width: 80%;
}

.learnmore-header h1 {
    font-size: 2.5rem;
    color: #004080;
    font-weight: bold;
}

.learnmore-header p {
    font-size: 1.2rem;
    color: #666;
}

/* Section Styles */
.learnmore-section {
    width: 100%;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 1.5rem 0;
}
.section-content{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.section-content h2 {
    color: #004080;
    margin-bottom: 1rem;
}

.section-content p {
    font-size: 1.1rem;
    color: #444;
    width: 80%;
}

/* Why Choose Us Section */
.why-choose .choose-us-content {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: space-between;
}

.choose-card {
    flex: 1 1 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    background: #f4f4f9;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    animation: sliideIn 0.8s ease;
}

.choose-icon {
    font-size: 2rem;
    color: #004080;
    margin-bottom: 1rem;
}



/* Our Products Section */
.section-content .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1.5rem;
    width: 90%;
}

.product-card {
    background: #f4f4f9;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
}

.product-card:hover {
    transform: scale(1.05);
}

.product-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
}

.product-card h3 {
    font-size: 1.2rem;
    margin: 1rem 0;
    color: #004080;
}

/* Environmental Impact Section */
.impact-section {
    background: #e0f7fa;
    color: #003366;
    text-align: center;
    padding: 2rem 1rem;
    border-radius: 10px;
    margin-top: 2rem;
}

.impact-section p {
    font-size: 1.2rem;
}

/* Animations */
@keyframes faadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes sliideIn {
    from { opacity: 0; transform: translateX(-20px); }
    to { opacity: 1; transform: translateX(0); }
}

/* Responsive Design */
@media screen and (max-width: 768px) {

    .learnmore-section {
        width: 100%;
        padding: 50px 0;
    }

    .choose-card, .product-card {
        flex: 1 1 100%;
    }

    .learnmore-header h1 {
        font-size: 2rem;
    }

    .section-content h2 {
        font-size: 1.5rem;
    }
    .section-content p{
        font-size: 0.95rem;
    }
}
