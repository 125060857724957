/* Footer.css */

.footer {
    background-color: #003366;
    color: #ffffff;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    border-top: 0.3px solid #0759ac;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-about,
  .footer-links,
  .footer-services,
  .footer-social {
    flex: 1 1 200px;
    min-width: 200px;
  }
  
  .footer-about h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .footer-about p {
    font-size: 0.9rem;
    line-height: 1.6;
  }
  
  .footer-links h3,
  .footer-services h3,
  .footer-social h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .footer-links ul,
  .footer-services ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li,
  .footer-services li {
    margin-bottom: 8px;
  }
  
  .footer-links a,
  .footer-services a {
    color: #ffffff;
    text-decoration: none;
    font-size: 0.9rem;
  }
  
  .footer-links a:hover,
  .footer-services a:hover {
    color: #ffd700; /* Gold color on hover */
  }
  
  .footer-social .social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  
  .footer-social a {
    color: #ffffff;
    font-size: 1.5rem;
    transition: color 0.3s;
  }
  
  .footer-social a:hover {
    color: #ffd700;
  }
  
  .footer-bottom {
    text-align: center;
    padding-top: 20px;
    font-size: 0.8rem;
    border-top: 1px solid #ffffff33;
  }

  .my-link{
    color: #12eeea;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-about,
    .footer-links,
    .footer-services,
    .footer-social {
      margin-bottom: -40px;
    }
  }
  