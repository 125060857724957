/* Advert.css */

.advert-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .advert-box {
    padding: 2rem;
    border-radius: 8px;
    color: white;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: fadeIn 1s ease;
  }
  
  .advert-box h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .advert-box p {
    font-size: 1rem;
  }
  
  .advert-box:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }
  
  .box1 {
    background: linear-gradient(135deg, #ff8a00, #da1b60);
    animation: slideInLeft 1s ease;
  }
  
  .box2 {
    background: linear-gradient(135deg, #1cb5e0, #000046);
    animation: slideInRight 1s ease;
  }
  
  .box3 {
    background: linear-gradient(135deg, #8e2de2, #4a00e0);
    animation: slideInBottom 1s ease;
  }
  
  .box4 {
    background: linear-gradient(135deg, #56ab2f, #a8e063);
    animation: slideInTop 1s ease;
  }
  
  /* Animations */
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideInLeft {
    from { transform: translateX(-100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
  
  @keyframes slideInRight {
    from { transform: translateX(100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
  
  @keyframes slideInTop {
    from { transform: translateY(-100%); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  @keyframes slideInBottom {
    from { transform: translateY(100%); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  /* Responsive Styles */
  
  @media (max-width: 768px) {
    .advert-box {
      font-size: 0.9rem;
      padding: 1.5rem;
    }
  }
  